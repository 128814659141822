import React, {useState, useEffect} from 'react';
import { Nav, NavLink, NavItem,} from "reactstrap";
import Spinner from '../components/Shared/Spinner';
import classnames from 'classnames';
import PaymentService from '../services/PaymentService';
import EnrollService from '../services/EnrollService';
import EnrollPaymentsModal from '../components/university/Enrolls/EnrollPaymentsModal';
import PaymentStatusModel from '../components/Modals/PaymentStatusModal';
import Dropdown from '../components/Shared/Dropdown';
import { connect } from 'react-redux';
import Moment from 'moment';
import {useUI, usePageUI} from '../app/context/ui';
import {useLocation, useHistory} from 'react-router-dom';
let option = {}
const label = (s) => {
  switch(s) {
    case 1 : option = {
              class: 'tag tag-success',
              label: 'Pagado'
            };
            break;
    case 3 : option = {
              class: 'tag tag-info',
              label: 'Exonerado'
            };
            break;
    case 4 : option = {
              class: 'tag tag-warning',
              label: 'Compromiso de Pago'
            };
            break;
    case 5 : option = {
              class: 'tag tag-primary',
              label: 'Retirado'
            };
            break;
    default: option = {
              class: 'tag tag-danger',
              label: 'Pendiente'
            };
            break;
  }
  return option;
}

const EnrollPaymentPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const {enroll} = location.state;
  const [activeTab, setActiveTab] = useState(1);
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [titleModalStatus, setTitleModalStatus] = useState('');
  const [status, setStatus] = useState(0);
  
  
  useEffect(() => {
    EnrollService.init(props.user.accessToken);
    PaymentService.init(props.user.accessToken);
    (async function getRecords() {
      await getPayments();
    })();
  }, [props.user])

  const getPayments = async () => {
    setIsLoading(true);
    try {
      const response = await EnrollService.listPayments(enroll.id);
      setPayments(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const getPaymentMethods = async () => {
    try {
      const response = await PaymentService.listMethods();
      setPaymentMethods(response.data);
    } catch (error) {
      console.log(error)
    }
  }

  const handlerOpenModal = (val) => {
    setOpenModal(val);
  }

  const handlerOpenModalStatus = (val) => {
    setOpenModalStatus(val);
  }

  const getPayment = (data) => {
    paymentUpdate(data);
  }

  const editPayment = (item) => {
    setPayment(item);
    getPaymentMethods();
    setOpenModal(true);
  }
  const exoneratePayment = (item) => {
    setPayment(item);
    setTitleModalStatus('Exonerar pago');
    setStatus(3);
    setOpenModalStatus(true);
  }

  const movePayment = (item) => {
    setPayment(item);
    setTitleModalStatus('Compromiso de Pago');
    setStatus(4);
    setOpenModalStatus(true);
  }

  const resetPayment = (item) => {
    const payment = {
      ...item,
      noOperation: null,
      paidAt: null,
      methodId: 1,
      total: 0,
      voucherThumbnail: null,
      penalty: null,
      discount: null,
      description: null,
      status: 2
    }
    paymentUpdate(payment);
  }

  const noPayment = (item) => {
    const payment = {
      ...item,
      total: 0,
      status: 5
    }
    paymentUpdate(payment);
  }

  const paymentUpdate = async (data) => {
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      const response = await PaymentService.update(data, data.id);
      getPayments(data.enrollId);
      blockUI.current.open(false);
      alertUI.current.open(true, response.data.message, 'success');
    } catch (error) {
      console.log(error);
      console.log(Object.values(error.response.data));
      blockUI.current.open(false);
      alertUI.current.open(true, Object.values(error.response.data)[1], 'danger');
    }
  }

  const downloadDocument = async (data) => {
    blockUI.current.open(true, 'Estamos buscando...');
    try {
      const response = await PaymentService.downloadDocument(data.id);
      console.log(response);
      let url = window.URL.createObjectURL(new Blob([response.data]));
      let file = document.createElement('a');
      file.href = url;
      file.download = `boleta-${data.id}.pdf`;
      file.click();
      blockUI.current.open(false);
      alertUI.current.open(true, 'Descarga satisfactoria', 'success');
    } catch (error) {
      console.log(error);
      blockUI.current.open(false);
      alertUI.current.open(true, 'No existe el Documento', 'danger');
    }
  }

  const setClick = (a,b) => {
    switch(a){
      case '1':          
                editPayment(b);
                break;
      case '2': resetPayment(b);
                break;
      case '3': exoneratePayment(b);
                break;
      case '4': movePayment(b);
                break;
      case '5': noPayment(b);
                break;
    }

  }

  return (
      <>
        <EnrollPaymentsModal
          isOpen={openModal}
          payment={payment}
          handlerOpenModal={handlerOpenModal}
          paymentMethods={paymentMethods}
          user={props.user}
          getPayment={getPayment}
        />
        <PaymentStatusModel
          isOpen={openModalStatus}
          payment={payment}
          handlerOpenModalStatus={handlerOpenModalStatus}
          getPayment={getPayment}
          user={props.user}
          title={titleModalStatus}
          status={status}
        />
        <div className="section-body">
          <div className="container-fluid">
            <div className="d-flex justify-content-between align-items-center ">
              <div className="header-action">
                <h1 className="page-title">Pagos de Pensiones</h1>
                <ol className="breadcrumb page-breadcrumb">
                    <li className="breadcrumb-item">{props.user.school ? props.user.school : 'YAKHANA'}</li>
                    <li className="breadcrumb-item" aria-current="page">Pagos de Pensiones</li>
                    <li className="breadcrumb-item active" aria-current="page">{enroll.userFullName}</li>
                </ol>
              </div>
              <Nav tabs className="page-header-tab">
                <NavItem>
                    <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => history.goBack()}
                    >
                    <i className="fa fa-angle-left"></i>Matricula
                    </NavLink>
                </NavItem>
              </Nav>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
      <div className="container-fluid">
        <div className="table-responsive card">
          <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
            <thead>
              <tr>
                <th>Tipo de pago</th>
                <th>Fecha de pago</th>
                <th>Monto</th>
                <th>Extra</th>
                <th>Mora</th>
                <th>Total</th>
                <th>Estado</th>
                <th>Documento</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
                {
                  payments.map(item => {
                    return(
                      <tr key={item.id}>
                        <td>
                          {item.productId === 1 ? "Matrícula" : `Pensión ${item.month}`}
                        </td>
                        <td>
                          {item.paidAt ? Moment(item.paidAt).format('DD/MM/YYYY') : item.paidAt}
                        </td>
                        <td className="w80">S/ {item.amount}</td>
                        <td className="w80">
                          {item.discount !== null  ? 'S/ ' + item.discount : ''}
                        </td>
                        <td className="w80">
                          {item.penalty !== null  ? 'S/ ' + item.penalty : ''}
                        </td>
                        <td className="w80 bold">
                          {item.total !== null ? 'S/ ' + item.total : ''}
                        </td>
                        <td className="text-center">
                          <span className={label(item.status).class}>
                            {label(item.status).label}
                          </span>
                        </td>
                        <td>
                        {
                          // item.status === 1 && (item.methodId === 6 || item.methodId === 8 || item.methodId === 7 || item.methodId === 10 || item.methodId === 11) && (
                          item.method.generateDocument === 1 && (
                            <button
                              onClick={() => downloadDocument(item)}
                              type="button"
                              className="btn btn-icon btn-sm js-sweetalert"
                              title="Boleta electrónica"
                              data-type="confirm"
                            >
                              <i className="fa fa-file-text-o"></i>
                            </button>
                          )
                        }
                        </td>
                        <td>
                          <Dropdown
                            setClick={setClick}
                            buttonDropdownName="Acciones"
                            actionsArray={
                              ( props.user.role === 'super-admin' || props.user.role === 'admin') ?

                              [
                                {function: '1', name: item.status === 2 ? 'Pagar' : 'Editar', data: item},
                                {function: '3', name: 'Exonerar', data: item},
                                {function: '4', name: 'Compromiso de pago', data: item},
                                {function: '5', name: 'Retirado', data: item},
                                {function: '2', name: 'Quitar pago', data: item},
                              ]
                              :
                              [
                                {function: '3', name: 'Exonerar', data: item},
                                {function: '4', name: 'Compromiso de pago', data: item},
                                {function: '5', name: 'Retirado', data: item},
                                {function: '2', name: 'Quitar pago', data: item},
                              ]
                            } 
                          />
                        </td>
                      </tr>
                    )
                  })
                }
            </tbody>
          </table>
          { isLoading && <Spinner />}
        </div>
      </div>
    </div>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(EnrollPaymentPage);
