import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  FormHelperText
} from "@mui/material";

const yearList = [{ value: 18, key: "2024" }];

const FilterDocuments = ({
  listSections,
  sections,
  handleChange,
  errors,
  changeSection
}) => {

  const changeYear = (event) => {
    listSections(event.target.value);
    handleChange(event);
  };

  const handleSection = (event) => {
    changeSection(event.target.value);
    handleChange(event);
  }

  return (
    <div className="row">
      <div className="col-lg-2 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="year-id-label">Año Lectivo</InputLabel>
          <Select
            labelId="school_year_id-label"
            id="school_year_id"
            name="school_year_id"
            label="Año Lectivo"
            onChange={changeYear}
          >
            <MenuItem value={`choose`}>Seleccionar</MenuItem>
            {yearList?.map((year, index) => (
              <MenuItem key={index} value={`${year.value}`}>
                {year.key}
              </MenuItem>
            ))}
          </Select>
          {errors.school_year_id && <FormHelperText>{errors.school_year_id}</FormHelperText>}
        </FormControl>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="section-id-label">Seccion</InputLabel>
          <Select
            labelId="section-id-label"
            id="section-id"
            name="section_id"
            label="Seccion"
            onChange={handleSection}
          >
            <MenuItem key="10000" value="choose">
              Seleccionar
            </MenuItem>
            {sections?.map((section, index) => (
              <MenuItem key={index} value={section.id}>
                {section.name}
              </MenuItem>
            ))}
          </Select>
          {errors.section_id && <FormHelperText>{errors.section_id}</FormHelperText>}
        </FormControl>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="report_card_type-label">Documento</InputLabel>
          <Select
            labelId="report_card_type-label"
            id="report_card_type"
            name="report_card_type"
            label="Documento"
            onChange={handleChange}
          >
            <MenuItem key="10000" value="choose">
              Seleccionar
            </MenuItem>
            <MenuItem key="10001" value="libreta">
              Libreta
            </MenuItem>
            {/* <MenuItem key="10002" value="boleta">
              Boleta
            </MenuItem> */}
          </Select>
          {errors.report_card_type && <FormHelperText>{errors.report_card_type}</FormHelperText>}
        </FormControl>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12">
        <FormControl fullWidth>
          <InputLabel id="period-id-label">Bimestre</InputLabel>
          <Select
            labelId="period-id-label"
            id="period-id"
            name="period_id"
            label="Bimestre"
            onChange={handleChange}
          >
            <MenuItem value="choose">Seleccionar</MenuItem>
            <MenuItem value={1}>Bimestre 1</MenuItem>
            <MenuItem value={2}>Bimestre 2</MenuItem>
            <MenuItem value={3}>Bimestre 3</MenuItem>
            <MenuItem value={4}>Bimestre 4</MenuItem>
          </Select>
          {errors.period_id && <FormHelperText>{errors.period_id}</FormHelperText>}
        </FormControl>
      </div>
      <div className="col-lg-2 col-md-6 col-sm-12">
        <Button color="primary" variant="contained" type="submit">
          Buscar
        </Button>
      </div>
    </div>
  );
};

export default FilterDocuments;
