import React, { useState, useEffect } from "react";
import { Formik, Form } from 'formik';
import * as yup from "yup";
import InputField from '../core/widgets/input.widget';
import SelectField from '../core/widgets/select.widget';
import Agent from '../models/Agent';

const digitsOnly = (value) => /^\d+$/.test(value);

const genderList = [
  {value: '', key: 'Seleccione un género'},
  {value: 'F', key: 'Femenino'},
  {value: 'M', key: 'Masculino'}
];

const AgentSchema = yup.object().shape({
  dni: yup
  .string()
  .required('Campo requerido')
  .typeError('El DNI solo debe contener números.')
  .test('Digits only', 'El DNI solo debe contener números', digitsOnly)
  .test('len', 'El DNI debe tener 8 digitos.', val => val && val.toString().length === 8 ),
  firstName: yup.string().required('Campo obligatorio'),
  lastName: yup.string().required('Campo obligatorio'),
  surname: yup.string().required('Campo obligatorio'),
  phone: yup.number().required('Campo obligatorio')
  .typeError('El teléfono solo debe contener números.')
  .test('len', 'El teléfono debe tener 9 digitos como maximo.', val => val && val.toString().length <= 9 ),
  email: yup.string().email(),
  gender: yup.string().required('Campo obligatorio'),
  address: yup.string().required('Campo obligatorio'),
  campusId: yup.number()
});

const AgentForm = ({agentData, saveAgent, searchDni}) => {

  const [agent, setAgent] = useState(Agent);

  useEffect(() => {
    setAgent(agentData)
  }, [agentData]);

  return (
    <>
      <div className="row clearfix">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">Información del Apoderado</h3>
            </div>
            <Formik
              validationSchema={AgentSchema}
              enableReinitialize={true}
              initialValues={
                {
                  ...agent
                }
              }
              onSubmit={(values, {resetForm}) => {
                saveAgent(values);
              }}
            >
              {(props) => (
                <Form className="card-body">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      DNI <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField
                        name="dni"
                        inputProps={{
                          maxLength: 8
                        }}
                        onChange={(e) => {
                          props.handleChange(e);
                          if(e.target.value.length > 7) {
                            searchDni(e.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Nombres <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="firstName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Ap. Paterno <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="lastName" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Ap. Materno <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="surname" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Género <span className="text-danger">*</span></label>
                    <div className="col-md-7">
                      <SelectField
                        name="gender"
                        data={genderList}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">Email</label>
                    <div className="col-md-7">
                      <InputField name="email" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Teléfono <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="phone" />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Dirección <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <InputField name="address" />
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="mr-1 btn btn-primary">
                      Guardar
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentForm;
