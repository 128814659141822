import React, {useState, useEffect} from 'react';
import Student from '../../models/Student';
import StudentForm from '../../forms/StudentForm';
import UserService from '../../services/UserService';
import {useUI, usePageUI} from '../../app/context/ui';
import { connect } from 'react-redux';
import AppHelper from '../../helpers/AppHelper';

const CreatePage = (props) => {
  const {alertUI} = usePageUI();
  const {blockUI} = useUI();
  const [isValid, setIsValid] = useState(false);
  const [student, setStudent] = useState(Student);

  useEffect(() => {
    UserService.init(props.user.accessToken, props.campus.id);
  }, [props.campus]);

  const saveStudent = async (student) => {
    blockUI.current.open(true, 'Estamos guardando...');
    try {
      const response = await UserService.create(student);
      if (response) {
        blockUI.current.open(false);
        alertUI.current.open(true, response.data.message, 'success');
        props.changeTab(1);
        setIsValid(true);
      }
    } catch (error) {
      blockUI.current.open(false);
      const err = error.response.data.errors;
      alertUI.current.open(true, AppHelper.showErrors(err), 'danger');
    }
  }

  const searchAgent = val => {
    props.searchAgent(val);
    setIsValid(false);
  }

  const searchDni = async(val) => {
    blockUI.current.open(true, 'Estamos buscando al alumno...');
    try {
      const response = await UserService.searchUserByDni(val);
      if(response.data) {
        blockUI.current.open(false);
        alertUI.current.open(true, response.data.message, 'success');
        setStudent({
        ...student,
        dni : response.data.dni,
        firstName: response.data.firstName,
        lastName: response.data.lastName,
        surname: response.data.surname,
        })
      }
    } catch (error) {
      blockUI.current.open(false);
      alertUI.current.open(true, 'Error', 'danger');
    }
  }
  
  return(
    <>
      <StudentForm
        studentData = {student}
        agents={props.agents}
        searchAgent={searchAgent}
        saveStudent={saveStudent}
        searchDni={searchDni}
        isValid={isValid}
      />
    </>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user,
    campus: state.campus
  };
};

export default connect(mapStateToProps)(CreatePage);